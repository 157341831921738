import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation, Toast as ToastInterface, ToastAPI } from 'contracts';

import Html from '../html';
import Icon, { IconList } from '../icon';
import renderElement from '../utils/render-element';

import styles from './toast.module.scss';

interface ToastElement extends StyledElement<HTMLButtonElement>, AriaAttributes, TestAutomation {
  defaultTimeout?: number;
}

const defaultSettings = {
  timeout: 5000,
  priority: 'medium' as ToastInterface['priority'],
  toastItemTestId: 'toast-item',
};

const priorityWeights: Record<Exclude<ToastInterface['priority'], undefined>, number> = {
  low: 1,
  medium: 2,
  high: 3,
  critical: 4,
};

const Toast = React.forwardRef<ToastAPI, ToastElement>((props, ref) => {
  const { testId, className, style, defaultTimeout = defaultSettings.timeout, ...rest } = props;
  const [activeToast, setActiveToast] = React.useState<ToastInterface | undefined>(undefined);
  const queue = React.useRef<Array<ToastInterface>>([]);
  const timeout = React.useRef<NodeJS.Timeout>();
  const activeToastTestId = activeToast?.testId ?? defaultSettings.toastItemTestId;

  const clearTimer = React.useCallback((): void => {
    clearTimeout(timeout.current);

    timeout.current = undefined;
  }, []);

  const setNextToast = React.useCallback((): void => {
    clearTimer();

    if (!queue.current.length) {
      // no more toasts in queue
      setActiveToast(undefined);

      return;
    }

    const next = queue.current.shift();

    setActiveToast(next);

    timeout.current = setTimeout(() => {
      next?.onClose?.();
      setNextToast();
    }, next?.timeout ?? defaultTimeout);
  }, [clearTimer, defaultTimeout]);

  const addToastToQueue = React.useCallback(
    (toast: ToastInterface): void => {
      if (queue.current.find((item) => item.id === toast.id)) {
        return;
      }

      const shouldDispatch = !queue.current.length && !timeout.current;

      queue.current = [...queue.current, toast].sort(
        (a, b) => priorityWeights[b.priority || 'medium'] - priorityWeights[a.priority || 'medium']
      );

      if (shouldDispatch) {
        setNextToast();
      }
    },
    [setNextToast]
  );

  const dispatch: ToastAPI['dispatch'] = React.useCallback(
    (toast) => {
      addToastToQueue(toast);
    },
    [addToastToQueue]
  );

  const remove: ToastAPI['remove'] = React.useCallback(
    (id) => {
      queue.current = queue.current.filter((item) => item.id !== id);

      if (activeToast?.id === id) {
        setNextToast();
      }
    },
    [activeToast, setNextToast]
  );

  const clear: ToastAPI['clear'] = React.useCallback(() => {
    clearTimer();
    queue.current = [];
    setActiveToast(undefined);
  }, [setActiveToast, clearTimer]);

  React.useImperativeHandle(ref, () => ({
    dispatch,
    remove,
    clear,
  }));

  React.useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleClose = React.useCallback((): void => {
    activeToast?.onClose?.();
    setNextToast();
  }, [activeToast, setNextToast]);

  const handleOnHover = React.useCallback(
    (enter: boolean) => () => {
      if (!activeToast?.freezeOnHover) {
        return;
      }

      if (enter) {
        clearTimer();
      } else {
        timeout.current = setTimeout(() => {
          activeToast?.onClose?.();
          setNextToast();
        }, activeToast.timeout || defaultTimeout);
      }
    },
    [activeToast, clearTimer, setNextToast, defaultTimeout]
  );

  if (!activeToast) return null;

  return (
    <Html.div
      testId={testId}
      className={[styles.toast, 'd-flex', 'justify-content-end', ...toArray(className)]}
      style={style}
      arias={rest}
    >
      <Html.div
        testId={activeToastTestId}
        style={activeToast.style}
        className={['p-4 rounded-1', styles.toastItem]}
        typography="caption"
        onClick={activeToast.onClick}
        onMouseEnter={handleOnHover(true)}
        onMouseLeave={handleOnHover(false)}
      >
        {activeToast.icon && (
          <Html.span className={['me-2', styles.prefix]}>{renderElement(activeToast.icon)}</Html.span>
        )}
        <Html.div className={styles.message} testId={`${activeToastTestId}-message`}>
          <Html.p typography="body2" className="mb-0">
            {activeToast.message}
          </Html.p>
          {activeToast.caption && (
            <Html.p typography="caption" className={[styles.caption, 'mb-0']}>
              {activeToast.caption}
            </Html.p>
          )}
        </Html.div>
        {activeToast.closable && (
          <Html.span className={['ms-2', styles.close]} onClick={handleClose} testId={`${activeToastTestId}-close`}>
            <Icon name={IconList.closeCircularSolid} />
          </Html.span>
        )}
      </Html.div>
    </Html.div>
  );
});

export type { ToastElement };
export { defaultSettings };
export default Toast;

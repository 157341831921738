import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { StyledElement, AriaAttributes, TestAutomation, RenderIconOrElement } from 'contracts';
import withGATracking from 'enhancers/with-ga-tracking';

import Html from '../html';
import renderElement from '../utils/render-element';

import styles from './icon-hover.module.scss';

interface IconHoverElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  active: RenderIconOrElement;
  inactive: RenderIconOrElement;
  forceActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const IconHover: React.FunctionComponent<IconHoverElement> = (props) => {
  const { testId, className, style, inactive, active, forceActive, onClick, ...rest } = props;

  return (
    <Html.span
      testId={testId}
      className={[styles.iconHover, forceActive && styles.forceActive, 'cursor-pointer', ...toArray(className)]}
      style={style}
      arias={rest}
      onClick={onClick}
    >
      {renderElement(inactive, undefined, {
        className:
          is.string(inactive) || is.func(inactive)
            ? styles.inactive
            : [...(inactive.props?.className ?? []), styles.inactive],
      })}
      {renderElement(active, undefined, {
        className:
          is.string(active) || is.func(active) ? styles.active : [...(active.props?.className ?? []), styles.active],
      })}
    </Html.span>
  );
};

export type { IconHoverElement };
export default withGATracking(IconHover, { action: 'click', handler: 'onClick' });

import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import is from 'utils/is';

import Html from '../html';

import styles from './divider.module.scss';

interface DividerElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  orientation?: 'left' | 'center' | 'right';
  variant?: 'primary' | 'secondary';
  theme?: 'light' | 'dark';
  children?: React.ReactNode;
}

const Divider: React.FunctionComponent<DividerElement> = (props) => {
  const { testId, variant = 'primary', theme = 'light', orientation, className, style, children, ...rest } = props;

  return (
    <Html.div
      testId={testId}
      className={[
        styles.divider,
        styles[variant as string],
        styles[theme as string],
        !is.nullish(children) && styles.withHeading,
        !is.nullish(children) && orientation === 'center' && styles.withHeadingCenter,
        !is.nullish(children) && orientation === 'right' && styles.withHeadingRight,
        ...toArray(className),
      ]}
      role="separator"
      aria-orientation="horizontal"
      style={style}
      arias={rest}
    >
      {children && <span className={styles.heading}>{children}</span>}
    </Html.div>
  );
};

export type { DividerElement };
export default Divider;

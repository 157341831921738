import React from 'react';
import { useIntl } from 'react-intl';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation, Tuple } from 'contracts';
import Html from 'components/html';

import messages from './relative-time.i18n';
import styles from './relative-time.module.scss';

interface RelativeTimeElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  children: number;
  highlight?: boolean;
}

const minute = 60;
const hour = 3600;
const day = 86400;
const week = 604800;

const RelativeTime: React.FunctionComponent<RelativeTimeElement> = (props) => {
  const { testId, className, style, children, highlight, ...rest } = props;
  const { formatMessage } = useIntl();

  const [relativeTime, dateIso] = React.useMemo(() => {
    const now = new Date().getTime();
    const timeDiff = (now - children) / 1000;
    const tuple = (value: string): Tuple<string, string> => [value, new Date(children).toISOString()];

    if (timeDiff < minute) return tuple(formatMessage(messages.components.relativeTime.now));
    if (timeDiff < hour)
      return tuple(formatMessage(messages.components.relativeTime.minute, { minute: Math.floor(timeDiff / minute) }));
    if (timeDiff < day)
      return tuple(formatMessage(messages.components.relativeTime.hour, { hour: Math.floor(timeDiff / hour) }));
    if (timeDiff < week)
      return tuple(formatMessage(messages.components.relativeTime.day, { day: Math.floor(timeDiff / day) }));

    return tuple(formatMessage(messages.components.relativeTime.week, { week: Math.floor(timeDiff / week) }));
  }, [children, formatMessage]);

  return (
    <Html.time
      testId={testId}
      className={[styles.relativeTime, highlight && styles.highlight, ...toArray(className)]}
      style={style}
      arias={rest}
      dateTime={dateIso}
    >
      {relativeTime}
    </Html.time>
  );
};

export type { RelativeTimeElement };
export default React.memo(RelativeTime);

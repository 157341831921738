import { defineMessages } from 'react-intl';

export default {
  components: {
    relativeTime: {
      ...defineMessages({
        now: {
          id: 'components.relativeTime.now',
          defaultMessage: 'now',
        },
        minute: {
          id: 'components.relativeTime.minute',
          defaultMessage: '{minute}m',
        },
        hour: {
          id: 'components.relativeTime.hour',
          defaultMessage: '{hour}h',
        },
        day: {
          id: 'components.relativeTime.day',
          defaultMessage: '{day}d',
        },
        week: {
          id: 'components.relativeTime.week',
          defaultMessage: '{week}w',
        },
      }),
    },
  },
};

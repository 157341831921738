import React from 'react';
import useSuspenseLazyLoad from 'enhancers/use-suspense-lazy-load';
import parse from 'utils/parse';
import cn from 'utils/class-name';
import filter from 'utils/filter';
import toArray from 'utils/to-array';
import is from 'utils/is';
import url from 'utils/uri';
import config from 'configurations/application';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';
import Loading from '../loading';

import type { LoyaltyLevel } from './contracts';
import { isGifMask } from './utils';
import styles from './loyalty-mask.module.scss';

interface LoyaltyMaskElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  level: LoyaltyLevel | undefined;
  size?: number;
  hasLabel?: boolean;
}

const LoyaltyMask: React.FunctionComponent<LoyaltyMaskElement> = (props) => {
  const { testId, className, style, level, size = 18, hasLabel = false, ...rest } = props;
  const Component = useSuspenseLazyLoad(async () => {
    const ext = isGifMask(level) ? '.gif' : '.svg';

    return import(/* webpackPreload: true */ `./assets/${parse.toKebabCase(level)}${ext}`).then((module) => {
      const content: unknown = module.default;

      if (is.string(content)) {
        const src: string = url.absolute(content) ? content : parse.url(config.envVars.wpp, content);

        return {
          default() {
            return (
              <Html.img
                testId={testId || parse.toKebabCase(level)}
                className={[styles.loyaltyMask, ...toArray(className)]}
                width={size}
                height={size}
                src={src}
                aria-hidden
              />
            );
          },
        };
      }

      return module;
    });
  }, [level]);

  const waiAria = filter.ariaAttributes(rest as Record<string, unknown>);

  if (level === undefined) return null;

  return (
    <React.Suspense fallback={<Loading size={18} />}>
      <Component
        data-testid={testId || parse.toKebabCase(level)}
        className={cn(styles.loyaltyMask, ...toArray(className))}
        style={{ ...style, height: size, width: size }}
        aria-hidden
        {...waiAria}
      />
      {hasLabel && <Html.span testId={testId && `${testId}-label`}>{` ${level}`}</Html.span>}
    </React.Suspense>
  );
};

export type { LoyaltyMaskElement, LoyaltyLevel };
export default React.memo(LoyaltyMask);

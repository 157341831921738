import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';

import Html from '../../../html';
import type { InternalComponent, InternalDividerElement } from '../../contracts';

import styles from './divider.module.scss';

const Divider: InternalComponent<HTMLHRElement, InternalDividerElement> = (props) => {
  const { testId, className, style, ...rest } = props;

  const isMobileDevice = React.useMemo(() => {
    return is.mobileDevice;
  }, []);

  if (isMobileDevice) {
    const value = '____________';

    return <Html.option disabled>{value}</Html.option>;
  }

  return <Html.hr testId={testId} className={[styles.divider, ...toArray(className)]} style={style} arias={rest} />;
};

export default Divider;

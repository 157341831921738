import React from 'react';
import useMobileView from 'enhancers/use-mobile-view';
import is from 'utils/is';
import { KeyboardKey } from 'contracts';
// eslint-disable-next-line no-duplicate-imports
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Modal, { type ModalElement } from '../modal';
import Drawer, { type DrawerElement } from '../drawer';

interface ModrawerAPI {
  close: () => void;
}

interface ModrawerElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  escapable?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modrawer = React.forwardRef<ModrawerAPI, ModrawerElement>((props, ref) => {
  const { testId, width, maxWidth, minWidth, escapable = true, onClose, className, style, children, ...rest } = props;
  const mobile = useMobileView();
  const Component: React.FunctionComponent<(DrawerElement | ModalElement) & React.RefAttributes<ModrawerAPI>> = mobile
    ? Drawer
    : Modal;

  const handleOnClose = React.useCallback((): void => {
    if (is.func(onClose)) {
      onClose();
    }
  }, [onClose]);

  const handleOnKeyDown = React.useCallback(
    (event: KeyboardEvent): void => {
      if ((event.key as KeyboardKey) === KeyboardKey.Escape) {
        handleOnClose();
      }
    },
    [handleOnClose]
  );

  React.useEffect(() => {
    if (!escapable) return;

    document.addEventListener('keydown', handleOnKeyDown);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [escapable, handleOnKeyDown]);

  return (
    <Component
      testId={testId}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      className={className}
      style={style}
      onClose={handleOnClose}
      escapable={escapable}
      {...rest}
      ref={ref}
    >
      {children}
    </Component>
  );
});

export type { ModrawerElement, ModrawerAPI };
export default Modrawer;

import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';

import styles from './loading.module.scss';

interface LoadingElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  size?: number;
  thickness?: number;
  /** Delay, in ms, to prevent component "flash" */
  delay?: number;
  center?: boolean;
  theme?: 'default' | 'light' | 'dark';
}

const Loading: React.FunctionComponent<LoadingElement> = (props) => {
  const {
    testId,
    size = 24,
    thickness = 2,
    delay = 300,
    center = true,
    theme = 'default',
    className,
    style,
    ...rest
  } = props;
  const [render, setRender] = React.useState<boolean>(!delay);

  React.useEffect(() => {
    if (!delay) return;

    const timeout = setTimeout(() => setRender(true), delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  const computedStyles = React.useMemo<React.CSSProperties>(() => {
    return { ...style, width: size, height: size, borderWidth: thickness } satisfies React.CSSProperties;
  }, [style, size, thickness]);

  if (!render) {
    return null;
  }

  return (
    <Html.span
      testId={testId}
      className={[
        'rounded-circle',
        styles.loading,
        ...toArray(className),
        center && styles.center,
        styles[theme as string],
      ]}
      style={computedStyles}
      aria-hidden
      arias={rest}
    />
  );
};

export type { LoadingElement };
export default Loading;

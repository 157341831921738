import type { ReactNode } from 'react';
import is from 'utils/is';
import type { PascalCase } from 'contracts';

import type { InternalElementType, PublicComponentSignature } from '../contracts';

import { ComponentSignature } from './component-signature';

type ElementQueries = { readonly [K in InternalElementType as `isPublic${PascalCase<K>}`]: boolean };

interface ElementInfo extends ElementQueries {
  readonly type: InternalElementType | undefined;
}

const element = (node: ReactNode): ElementInfo => {
  const signature: symbol | undefined = is.object<PublicComponentSignature<HTMLElement, unknown>>(node)
    ? node.type.signature
    : undefined;

  return {
    get type() {
      switch (signature) {
        case ComponentSignature.SELECT:
          return 'select';
        case ComponentSignature.OPTION_GROUP:
          return 'option-group';
        case ComponentSignature.OPTION:
          return 'option';
        case ComponentSignature.DIVIDER:
          return 'divider';
        default:
          return undefined;
      }
    },
    get isPublicSelect() {
      return this.type === 'select';
    },
    get isPublicOptionGroup() {
      return this.type === 'option-group';
    },
    get isPublicOption() {
      return this.type === 'option';
    },
    get isPublicDivider() {
      return this.type === 'divider';
    },
  };
};

export default element;

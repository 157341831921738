import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';

import type { InternalComponent, InternalOptionElement } from '../../contracts';
import Html from '../../../html';

import styles from './option.module.scss';

const Option: InternalComponent<HTMLButtonElement, InternalOptionElement> = (props) => {
  const { testId, value, selected, disabled, onSelect, className, style, children, ...rest } = props;

  const isMobileDevice = React.useMemo(() => {
    return is.mobileDevice;
  }, []);

  const handleOnClick = React.useCallback((): void => {
    if (disabled) return;

    onSelect(value);
  }, [onSelect, value, disabled]);

  if (isMobileDevice) {
    return (
      <Html.option value={value} disabled={disabled} arias={rest}>
        {children}
      </Html.option>
    );
  }

  return (
    <Html.button
      type="button"
      testId={testId}
      onClick={handleOnClick}
      className={[styles.option, 'mb-0', selected && styles.selected, ...toArray(className)]}
      style={style}
      disabled={disabled}
      aria-selected={selected}
      role="option"
      arias={rest}
      typography="body2"
      tabIndex={-1}
    >
      {children}
    </Html.button>
  );
};

export default Option;

import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation, Size } from 'contracts';
import parse from 'utils/parse';

import Html from '../html';

import type { IconList } from './icon-list';
import styles from './icon.module.scss';

// To use a gradient ensure you're using a "background:" css property instead of "color:", and set hasGradientBg to true
interface IconElement extends StyledElement<SVGAElement>, AriaAttributes, TestAutomation {
  name: IconList;
  size?: Size;
  color?: string;
  muted?: boolean | 'hover';
  hasGradientBg?: boolean;
  hasTransparentUnderlay?: boolean;
}

const Icon: React.FunctionComponent<IconElement> = (props) => {
  const {
    name,
    size = 24,
    color,
    hasGradientBg,
    hasTransparentUnderlay,
    className,
    style,
    muted,
    testId,
    ...rest
  } = props;

  return (
    <Html.i
      testId={testId}
      className={[
        styles.icon,
        styles[parse.toCamelCase(`icon-${name}`)],
        hasGradientBg && styles.withGradient,
        hasTransparentUnderlay && styles.transparentUnderlay,
        muted && styles.muted,
        muted === 'hover' && styles.mutedHover,
        ...toArray(className),
      ]}
      style={{
        fontSize: size,
        color: !muted ? color : undefined,
        ...style,
      }}
      aria-hidden
      arias={rest}
    />
  );
};

export type { IconElement };
export default Icon;

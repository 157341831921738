import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import withGATracking from 'enhancers/with-ga-tracking';
import is from 'utils/is';
import parse from 'utils/parse';

import Html from '../html';
import Loading from '../loading';

import styles from './button.module.scss';

type HTMLButton = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface ButtonElement extends StyledElement<HTMLButtonElement>, AriaAttributes, TestAutomation {
  type?: HTMLButton['type'];
  onClick?: HTMLButton['onClick'];
  onKeyDown?: HTMLButton['onKeyDown'];
  disabled?: boolean;
  children: React.ReactNode;
  withFocus?: boolean;
  loading?: boolean;
  variant?:
    | 'primary'
    | 'secondary1'
    | 'secondary2'
    | 'minimal'
    | 'text-primary'
    | 'text-secondary1'
    | 'text-secondary2';
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
}

const Button: React.FunctionComponent<ButtonElement> = (props) => {
  const {
    type = 'button',
    variant = 'primary',
    size = 'm',
    disabled,
    withFocus,
    role,
    children,
    className,
    style,
    testId,
    onClick,
    onKeyDown,
    loading,
    ...rest
  } = props;
  const ref = React.useRef<HTMLButtonElement>();

  React.useEffect(() => {
    if (!withFocus || !ref.current) return;

    ref.current.focus();
  }, [withFocus]);

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || disabled) {
        event.preventDefault();

        return;
      }

      ref.current?.classList.add(styles.active);
      if (is.func(onClick)) {
        onClick(event);
      }
    },
    [onClick, loading, disabled]
  );

  const handleOnKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (loading || disabled) {
        event.preventDefault();

        return;
      }

      if (is.func(onKeyDown)) {
        onKeyDown(event);
      }
    },
    [onKeyDown, loading, disabled]
  );

  const isDisabled = loading || disabled;

  return (
    <Html.button
      type={type}
      className={[
        styles.button,
        size && styles[size],
        variant && styles[parse.toCamelCase(variant)],
        disabled && styles.disabled,
        ...toArray(className),
      ]}
      style={style}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      disabled={isDisabled}
      testId={testId}
      arias={rest}
      ref={ref}
      role={role}
    >
      {children}
      {loading && (
        <Loading
          testId={testId && `${testId}-loading`}
          size={14}
          thickness={2}
          delay={0}
          theme="dark"
          className={styles.loading}
        />
      )}
    </Html.button>
  );
};

export type { ButtonElement };
export default withGATracking(Button, { action: 'click', handler: 'onClick' });

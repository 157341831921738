import React from 'react';
import invariant from 'invariant';
// eslint-disable-next-line no-restricted-imports
import ff, { flags } from 'services/feature-toggle';
import cn from 'utils/class-name';
import is from 'utils/is';
import toArray from 'utils/to-array';
import env from 'utils/environment';
import filter from 'utils/filter';
import parse from 'utils/parse';

import type { FactoryBuilder, HTMLElementOf, HTMLElementProps } from './contracts';
import styles from './html.module.scss';

const blacklist: string[] = ['data-test', 'data-test-id', 'data-testid', 'data-testId', 'dataTestId'];

const factory: FactoryBuilder = (tag) => {
  return React.forwardRef<HTMLElementOf<typeof tag>, HTMLElementProps<typeof tag>>((props, ref) => {
    const { className, testId, uppercase, italic, ellipsis, typography, fontWeight, children, arias, ...rest } = props;

    const ariaAttributes = filter.ariaAttributes(arias);

    React.useLayoutEffect(() => {
      const result = Object.keys(rest).find((prop) => blacklist.includes(prop));

      invariant(!result, `[HTML]: expected usage of "testId" as property, but instead got "${result!}"`);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ellipsisLines = React.useMemo(() => {
      if (!ellipsis) return undefined;

      return is.number(ellipsis) ? ellipsis : 1;
    }, [ellipsis]);

    const Component = tag as React.ElementType;

    return (
      <Component
        {...rest}
        {...ariaAttributes}
        data-testid={env.isProduction && ff.disabled(flags.enableDataTestID) ? undefined : testId}
        className={cn(
          ...toArray(typography),
          fontWeight && `fw-${parse.toKebabCase(fontWeight)}`,
          italic && styles.italic,
          uppercase && styles.uppercase,
          ellipsisLines && styles.ellipsis,
          ellipsisLines && styles[`ellipsis${ellipsisLines}`],
          ...toArray(className)
        )}
        ref={ref}
      >
        {children}
      </Component>
    );
  });
};

export default factory;

import type { EventHandler } from 'react';
import is from 'utils/is';
import type { Tuple } from 'contracts';

import type {
  HTMLEvent,
  HorizontalDirection,
  PopoverPosition,
  VerticalDirection,
  TriggerEventCallback,
  TriggerListeners,
  PopoverTrigger,
} from './contracts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NormalisedTriggers = Array<HTMLEvent | Tuple<string, boolean | TriggerEventCallback<any>>>;

interface Directions {
  readonly vertical: VerticalDirection[];
  readonly horizontal: HorizontalDirection[];
}

const directions: Directions = {
  vertical: ['top', 'bottom'],
  horizontal: ['right', 'left'],
};

const normaliseTrigger = (trigger: PopoverTrigger): NormalisedTriggers => {
  if (!trigger) return [];

  if (is.string(trigger)) {
    return [trigger];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(trigger) as Array<Tuple<string, TriggerEventCallback<any>>>;
};

const fitsAt = (
  orientation: PopoverPosition,
  boundaryRect: DOMRect,
  wrapperRect: DOMRect,
  contentRect: DOMRect
): boolean => {
  switch (orientation) {
    case 'top':
    case 'top-left':
    case 'top-right':
      return wrapperRect.top - contentRect.height > boundaryRect.top;
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      return wrapperRect.bottom + contentRect.height < boundaryRect.bottom;
    case 'right':
    case 'right-top':
    case 'right-bottom':
      return wrapperRect.right + contentRect.width < boundaryRect.right;
    case 'left':
    case 'left-top':
    case 'left-bottom':
      return wrapperRect.left - contentRect.width > boundaryRect.left;
    default:
      return false;
  }
};

const mirrorDirection = (direction: VerticalDirection | HorizontalDirection | 'center'): PopoverPosition | 'center' => {
  switch (direction) {
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'right':
      return 'left';
    case 'left':
      return 'right';
    case 'center':
      return 'center';
    default:
      throw new RangeError(`${direction as string} is not within the range.`);
  }
};

const parseToTriggerListeners = (
  trigger: PopoverTrigger,
  interceptor: (callback: boolean | ((prev: boolean) => boolean)) => void
): TriggerListeners => {
  return normaliseTrigger(trigger).reduce((acc, next) => {
    if (is.string(next)) {
      return [...acc, [next, (): void => interceptor((prev) => !prev)]] as TriggerListeners;
    }

    const [event, callback] = next;

    if (is.bool(callback)) {
      return [...acc, [event, (): void => interceptor(() => callback)]] as TriggerListeners;
    }

    return [
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [event, <T extends EventHandler<any>>(e: T): void => interceptor((prev) => Boolean(callback(prev, e)))],
    ] as TriggerListeners;
  }, [] as TriggerListeners);
};

export { fitsAt, mirrorDirection, directions, parseToTriggerListeners };

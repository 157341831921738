import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';

import styles from './badge.module.scss';

interface BadgeElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  variant?: 'default';
  children?: React.ReactNode;
}

const Badge: React.FunctionComponent<BadgeElement> = (props) => {
  const { testId, className, style, children, variant, ...rest } = props;

  return (
    <Html.span
      testId={testId}
      className={[styles.badge, ...toArray(className), variant && styles[variant], !children && styles.noContent]}
      style={style}
      arias={rest}
      typography="fineprint2"
      fontWeight="bold"
    >
      {children}
    </Html.span>
  );
};

export type { BadgeElement };
export default Badge;

import type { LoyaltyLevel } from './loyalty-mask';
import { loyaltyLevels } from './loyalty-mask.settings';

const isLoyaltyLevel = (value: string | undefined): value is LoyaltyLevel =>
  ([...loyaltyLevels, undefined] as (typeof value)[]).includes(value);

// @ts-expect-error: wrong assertion
const isGifMask = (mask: LoyaltyLevel | undefined): boolean => (['Eros'] satisfies Array<LoyaltyLevel>).includes(mask);

export { isLoyaltyLevel, isGifMask };

import React from 'react';
import useMobileView from 'enhancers/use-mobile-view';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import toArray from 'utils/to-array';

import Html from '../html';
import Tooltip, { type TooltipElement } from '../tooltip';
import Drawer from '../drawer';

import styles from './tooltip-drawer.module.scss';

interface TooltipDrawerElement
  extends StyledElement<HTMLDivElement>,
    AriaAttributes,
    TestAutomation,
    Partial<Pick<TooltipElement, 'position'>> {
  content: React.ReactNode;
  children: React.ReactNode;
}

const TooltipDrawer: React.FunctionComponent<TooltipDrawerElement> = (props) => {
  const { testId, position = 'right', content, className, style, children, ...rest } = props;
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const mobile = useMobileView();

  const handleCloseDrawer = React.useCallback(() => {
    setModalVisibility(false);
  }, []);

  const handleOpenDrawer = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setModalVisibility(true);
  }, []);

  if (mobile) {
    return (
      <React.Fragment>
        <Html.span onClickCapture={handleOpenDrawer}>{children}</Html.span>
        {modalVisibility && (
          <Drawer
            testId={testId}
            className={[styles.drawer, ...toArray(className)]}
            variant="secondary"
            style={style}
            onClose={handleCloseDrawer}
            {...rest}
          >
            {content}
          </Drawer>
        )}
      </React.Fragment>
    );
  }

  return (
    <Tooltip content={content} position={position} testId={testId} className={className} style={style} {...rest}>
      {children}
    </Tooltip>
  );
};

export type { TooltipDrawerElement };
export default TooltipDrawer;

import type { DOMAttributes } from 'react';
import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { throttle, debounce } from 'throttle-debounce';
import useResizeObserver from 'enhancers/use-resize-observer';
import toArray from 'utils/to-array';
import is from 'utils/is';
import parse from 'utils/parse';

import Html from '../html';

import type {
  HorizontalDirection,
  VerticalDirection,
  MainDirection,
  PopoverElement,
  PopoverPosition,
  PopoverControl,
} from './contracts';
import { mirrorDirection, fitsAt, directions, parseToTriggerListeners } from './utils';
import styles from './popover.module.scss';

type WrapperEvents = keyof Omit<DOMAttributes<HTMLDivElement>, 'dangerouslySetInnerHTML' | 'children'>;
type WrapperEventListener = Record<WrapperEvents, () => void>;

const Popover = React.forwardRef<PopoverControl, PopoverElement>((props, ref) => {
  const {
    testId,
    open: initialStatus,
    position,
    boundary,
    content,
    trigger,
    escapable = true,
    closeOnContentClick = true,
    disabled,
    onToggle,
    className,
    contentClassName,
    style,
    children,
    ...rest
  } = props;
  const [contentPositioning, setContentPosition] = React.useState<React.CSSProperties>({});
  const [open, toggleStatus] = useState<boolean>(initialStatus === true);
  const positioning = useRef<MainDirection>(position.split('-')[0] as MainDirection);
  const wrapperRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const mounted = useRef<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnToggle = React.useCallback(
    debounce(15, (status: boolean, direction: MainDirection) => {
      if (disabled || !onToggle) return;

      mounted.current = true;

      onToggle(status, direction, mounted.current);
    }),
    [disabled, onToggle]
  );

  const computePosition = useCallback((): void => {
    if (!wrapperRef.current || !contentRef.current) {
      return;
    }

    const effectiveBoundary: DOMRect =
      boundary?.current?.getBoundingClientRect() ?? document.documentElement.getBoundingClientRect();
    const contentRect = contentRef.current.getBoundingClientRect();
    const wrapperRect = wrapperRef.current.getBoundingClientRect();

    let availablePosition: PopoverPosition = position;

    if (!fitsAt(availablePosition, effectiveBoundary, wrapperRect, contentRect)) {
      const [primary, secondary] = position.split('-') as Array<VerticalDirection | HorizontalDirection>;

      const nextAttempts = [
        `${mirrorDirection(primary)}${secondary ? `-${secondary}` : ''}`,
        `${mirrorDirection(primary)}${secondary ? `-${mirrorDirection(secondary)}` : ''}`,
        secondary,
        secondary && mirrorDirection(secondary),
      ].filter(Boolean) as PopoverPosition[];

      availablePosition =
        nextAttempts.find((entry) => fitsAt(entry, effectiveBoundary, wrapperRect, contentRect)) ?? position;
    }

    const [direction, modifier] = availablePosition.split('-');

    const computed: React.CSSProperties = {};

    positioning.current = direction as MainDirection;

    const isHorizontalOrientation = directions.horizontal.includes(direction as HorizontalDirection);
    const isVerticalOrientation = directions.vertical.includes(direction as VerticalDirection);

    // main direction
    if (isHorizontalOrientation) {
      computed[direction === 'left' ? 'right' : 'left'] = '100%';
    }

    if (isVerticalOrientation) {
      computed[direction === 'top' ? 'bottom' : 'top'] = '100%';
    }

    // modifier
    if (directions.horizontal.includes(modifier as HorizontalDirection)) {
      if (directions.horizontal.includes(direction as HorizontalDirection)) {
        computed[modifier === 'left' ? 'right' : 'left'] = '100%';
      } else {
        computed[modifier] = 0;
      }
    } else if (directions.vertical.includes(modifier as VerticalDirection)) {
      computed[modifier as VerticalDirection] = 0;
    } else {
      computed[isHorizontalOrientation ? 'top' : 'left'] = '50%';
      computed[isHorizontalOrientation ? 'marginTop' : 'marginLeft'] =
        // eslint-disable-next-line no-bitwise
        ((isHorizontalOrientation ? contentRect.height : contentRect.width) >> 1) * -1;
    }

    setContentPosition(computed);
  }, [boundary, position]);

  const interceptToggleState = React.useCallback(
    (callback: boolean | ((prev: boolean) => boolean)): void => {
      toggleStatus((prev) => {
        const result = is.func(callback) ? callback(prev) : callback;

        if (prev !== result) {
          handleOnToggle(result, positioning.current);
        }

        return result;
      });
    },
    [handleOnToggle]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      open: () => interceptToggleState(true),
      close: () => interceptToggleState(false),
      toggle: () =>
        interceptToggleState((prev) => {
          return !prev;
        }),
      get isOpen() {
        return open;
      },
    }),
    [interceptToggleState, open]
  );

  const handleOnClickOutside = (event: MouseEvent): void => {
    if (!wrapperRef.current!.contains(event.target as Node)) {
      interceptToggleState(false);
    }
  };

  useLayoutEffect(() => {
    if (disabled) return;

    const throttledCallback = throttle(100, computePosition);

    window.addEventListener('resize', throttledCallback);

    if (escapable) {
      document.addEventListener('click', handleOnClickOutside, false);
    }

    return () => {
      window.removeEventListener('resize', throttledCallback);

      if (escapable) {
        document.removeEventListener('click', handleOnClickOutside, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, escapable, disabled, computePosition]);

  useLayoutEffect(() => {
    computePosition();
  }, [computePosition, open]);

  useResizeObserver(contentRef, computePosition);

  const eventListeners = React.useMemo(() => {
    if (!trigger) return {};

    const listeners = parseToTriggerListeners(trigger, interceptToggleState);

    return listeners.reduce((acc, [event, callback]) => {
      acc[parse.toCamelCase(event) as WrapperEvents] = callback;

      return acc;
    }, {} as WrapperEventListener);
  }, [interceptToggleState, trigger]);

  const contentClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    (e) => {
      if (!closeOnContentClick) {
        e.stopPropagation();
      }
    },
    [closeOnContentClick]
  );

  return (
    <Html.div
      role="presentation"
      testId={testId}
      className={[styles.popover, ...toArray(className)]}
      style={style}
      arias={rest}
      {...eventListeners}
      ref={wrapperRef}
    >
      {children}
      {!disabled && (
        <Html.div
          role="presentation"
          testId={testId && parse.toTestId(testId, 'content')}
          className={[styles.content, open && styles.contentOpen, ...toArray(contentClassName)]}
          style={contentPositioning}
          hidden={!open}
          ref={contentRef}
          onClick={contentClick}
        >
          {is.func(content) ? content(positioning.current) : content}
        </Html.div>
      )}
    </Html.div>
  );
});

export default React.memo(Popover);

// Threshold represents the minimum distanceto run in order to close the Drawer on drag.
const thresholdMin = 50;
const thresholdMax = 200;
// percentage of the drawer (0.5 = 50%)
const thresholdPercentage = 0.5;

// Hide the bottom scroll indicator when {scrollIndicatorBottomThreshold}px distant to the bottom.
const scrollIndicatorBottomThreshold = 42;

// Hide the top scroll indicator when {scrollIndicatorTopThreshold}px distant to the top.
const scrollIndicatorTopThreshold = 24;

export { thresholdMin, thresholdMax, thresholdPercentage, scrollIndicatorBottomThreshold, scrollIndicatorTopThreshold };

import React from 'react';
import is from 'utils/is';
import render from 'utils/render';
import type { RenderIconOrElement, Size } from 'contracts';

import Icon, { IconList } from '../icon';

const renderElement = (Element: RenderIconOrElement, iconSize: Size = 24, props = {}): React.ReactNode => {
  if (is.string<IconList>(Element) && Object.values(IconList).includes(Element)) {
    return <Icon name={Element} size={iconSize} {...props} />;
  }

  return render.element(Element, props);
};

export default renderElement;

import React from 'react';
import anime from 'animejs';
import withGATracking from 'enhancers/with-ga-tracking';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';

import styles from './hamburger.module.scss';

interface HamburgerElement
  extends StyledElement<HTMLDivElement>,
    Omit<AriaAttributes, 'aria-label' | 'aria-controls' | 'aria-expanded'>,
    TestAutomation {
  active: boolean;
  ariaLabel: string;
  /** Element ID that is controlled by this component */
  ariaControls: string;
  onToggle: (open: boolean) => void;
}

const hamburgerSolidPath =
  'M3.0204,19.5375v-2.35H21v2.35H3.0204Zm0-6.35v-2.35H21v2.35H3.0204Zm-.0204-6.35v-2.35H20.9796v2.35H3Z';
const hamburgerOutlinePath = 'M3,18.25v-1.5H21v1.5H3Zm0-5.5v-1.5H21v1.5H3Zm0-5.5v-1.5H21v1.5H3Z';

const Hamburger: React.FunctionComponent<HamburgerElement> = (props) => {
  const { active, onToggle, testId, ariaLabel, ariaControls, className, style, ...rest } = props;
  const pathRef = React.useRef<SVGPathElement>(null);

  const handleOnClick = React.useCallback((): void => {
    if (is.func(onToggle)) {
      onToggle(!active);
    }
  }, [active, onToggle]);

  React.useEffect(() => {
    anime({
      targets: pathRef.current,
      easing: 'spring(1, 100, 10, 10)',
      duration: 350,
      d: [
        {
          value: active ? hamburgerSolidPath : hamburgerOutlinePath,
        },
      ],
    });
  }, [active]);

  return (
    <Html.button
      testId={testId}
      className={[styles.hamburger, active && styles.active, ...toArray(className)]}
      onClick={handleOnClick}
      style={style}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={Boolean(active)}
      arias={rest}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24">
        <path d={hamburgerOutlinePath} ref={pathRef} />
      </svg>
    </Html.button>
  );
};

export type { HamburgerElement };
export default withGATracking(Hamburger, { action: 'toggle', handler: 'onToggle' });

import React from 'react';
import { throttle } from 'throttle-debounce';
import { useDrag } from '@use-gesture/react';
import useBodyScrollLock from 'enhancers/use-body-scroll-lock';
import css from 'utils/css';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Portal from '../portal';
import Html from '../html';

import styles from './drawer.module.scss';
import {
  thresholdMin,
  thresholdMax,
  thresholdPercentage,
  scrollIndicatorBottomThreshold,
  scrollIndicatorTopThreshold,
} from './drawer.settings';

interface DrawerAPI {
  close: () => void;
}

interface DrawerElement extends StyledElement<HTMLDivElement>, AriaAttributes, TestAutomation {
  escapable?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

const Drawer = React.forwardRef<DrawerAPI, DrawerElement>((props, ref) => {
  const { testId, escapable = true, variant = 'primary', children, onClose, className, style, ...rest } = props;
  const contentRef = React.useRef<HTMLDivElement>();
  const scrollTopIndicatorRef = React.useRef<HTMLDivElement>();
  const scrollBottomIndicatorRef = React.useRef<HTMLDivElement>();
  const drawerRef = React.useRef<HTMLDivElement>();
  const initialHeight = React.useRef<number>(0);
  const drawerPaddingTop = React.useRef<number>(0);
  const draggable = escapable !== false;

  useBodyScrollLock(true);

  React.useLayoutEffect(() => {
    drawerPaddingTop.current = css(window.getComputedStyle(drawerRef.current!).paddingTop).size;
  }, []);

  const stopBubbling = React.useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  }, []);

  const escape = React.useCallback((): void => {
    if (!escapable) return;

    drawerRef.current?.classList.add(styles.close);
  }, [escapable]);

  React.useImperativeHandle(ref, () => ({
    close: escape,
  }));

  const updateScrollIndicatorPosition = React.useCallback(() => {
    if (!scrollTopIndicatorRef.current) return;

    // minus one, fixes iphone safari mismatch
    const offsetTopSum = drawerRef.current!.offsetTop + contentRef.current!.offsetTop - 1;

    scrollTopIndicatorRef.current.style.top = `${offsetTopSum}px`;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContentScroll = React.useCallback(
    throttle(100, (): void => {
      const { current } = contentRef;

      if (!current) return;

      const isAtTheBottom =
        current.scrollTop + current.offsetHeight + scrollIndicatorBottomThreshold > current?.scrollHeight;

      const bottomMethod = isAtTheBottom ? 'add' : 'remove';

      scrollBottomIndicatorRef.current?.classList[bottomMethod]('d-none');

      const topMethod = current.scrollTop > scrollIndicatorTopThreshold ? 'remove' : 'add';

      scrollTopIndicatorRef.current?.classList[topMethod]('d-none');

      updateScrollIndicatorPosition();
    }),
    []
  );

  const handleAnimationEnd = React.useCallback(() => {
    if (drawerRef.current!.classList.contains(styles.close)) {
      drawerRef.current!.classList.remove(styles.maximized);
      contentRef.current!.classList.remove(styles.close);
      onClose();
    }

    contentRef.current!.style.height = `${drawerRef.current!.offsetHeight - drawerPaddingTop.current}px`;
    const visibleContentHeight = contentRef.current?.offsetHeight ?? 0;
    const totalHeight = contentRef.current?.scrollHeight ?? 0;
    const isContentBiggerThanMaxHeight = totalHeight > visibleContentHeight;

    if (isContentBiggerThanMaxHeight) {
      scrollBottomIndicatorRef.current?.classList.remove('d-none');
    }
  }, [onClose]);

  React.useEffect(() => {
    drawerRef.current!.addEventListener('animationend', handleAnimationEnd);
    contentRef.current!.addEventListener('scroll', handleContentScroll);

    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      drawerRef.current?.removeEventListener('animationend', handleAnimationEnd);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      contentRef.current?.removeEventListener('scroll', handleContentScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bindDrag = useDrag(
    ({ movement: [, my], last, first }) => {
      if (first) {
        initialHeight.current = drawerRef.current!.offsetHeight;
        contentRef.current!.style.height = `${initialHeight.current - drawerPaddingTop.current - my}px`;
      }

      if (last) {
        const { offsetHeight } = drawerRef.current!;
        const delta = Math.min(Math.max(thresholdMin, offsetHeight * thresholdPercentage), thresholdMax);

        if (my > delta) {
          escape();

          return;
        }
        drawerRef.current!.style.height = '';
        contentRef.current!.style.height = `${initialHeight.current - drawerPaddingTop.current}px`;
        updateScrollIndicatorPosition();

        return;
      }

      drawerRef.current!.style.height = `${initialHeight.current - my}px`;
      updateScrollIndicatorPosition();
    },
    {
      delay: true,
      enabled: draggable,
    }
  );

  return (
    <Portal>
      <Html.div
        testId={testId && `${testId}-scrim`}
        className={[styles.scrim, variant === 'secondary' && styles.scrimSecondary]}
        onClick={escape}
      >
        <Html.div
          testId={testId}
          className={[
            styles.drawer,
            styles.open,
            styles[variant as string],
            draggable ? 'pt-12' : 'pt-7',
            ...toArray(className),
          ]}
          ref={drawerRef}
          role="dialog"
          aria-modal="true"
          style={style}
          arias={rest}
          onClick={stopBubbling}
        >
          {variant !== 'secondary' && (
            <Html.div ref={scrollTopIndicatorRef} className={[styles.scrollTopIndicator, 'd-none']} />
          )}
          {draggable && (
            <Html.div className={[styles.dragHandle, 'pt-12']} {...bindDrag()} testId={testId && `${testId}-handle`} />
          )}
          <Html.div
            className={[styles.content, variant !== 'secondary' && styles.scrollbar, 'px-8 pb-8']}
            ref={contentRef}
            testId={testId && `${testId}-content`}
          >
            {children}
          </Html.div>
          {variant !== 'secondary' && (
            <Html.div ref={scrollBottomIndicatorRef} className={[styles.scrollBottomIndicator, 'd-none']} />
          )}
        </Html.div>
      </Html.div>
    </Portal>
  );
});

export type { DrawerElement, DrawerAPI };
export default Drawer;

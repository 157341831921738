import shallowCopy from 'utils/shallow-copy';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
type PipeArgumentType = string | number | boolean | undefined | Record<string, any> | Array<PipeArgumentType>;

type PipeMiddleware<T = any, D = T> = (data: D, ...options: Array<PipeArgumentType>) => T | Array<T>;

/**
 * Data Pipeline
 * @return {Pipeline<T>}
 */
interface Pipeline<T> {
  /**
   * Register new pipeline middleware
   * @param {PipeMiddleware<T>} middleware
   * @param {...PipeArgumentType} options
   * @return {Pipeline<T>}
   */
  use: (middleware: PipeMiddleware, ...options: PipeArgumentType[]) => Pipeline<T>;
  /**
   * Process data through declared pipes
   * @param {any | any[]} data
   * @return {T}
   */
  process: (data: any) => T;
}

/**
 * Data Pipeline
 * @return {Pipeline<T>}
 */
const pipeline = <T = any>(): Pipeline<T> => {
  const middlewares = new Map<PipeMiddleware, PipeArgumentType[]>();

  return {
    use(middleware, ...options) {
      middlewares.set(middleware, options);

      return this;
    },
    process(data) {
      let transformedData = shallowCopy(data);

      middlewares.forEach((options, middleware) => {
        transformedData = middleware(transformedData, ...options);
      });

      return transformedData as T;
    },
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

export type { PipeMiddleware };
export default pipeline;

import invariant from 'invariant';
import toArray from 'utils/to-array';
import shallowCopy from 'utils/shallow-copy';
import parse from 'utils/parse';
import is from 'utils/is';
import type { TypeOrArray } from 'contracts';

import type { Template, InternalElementType } from '../contracts';
import type { PipeMiddleware } from '../utils/pipeline';
import element from '../utils/element';

const getCompositionWhitelist = (node: InternalElementType): Array<InternalElementType | undefined> => {
  const external: InternalElementType | undefined = undefined;

  switch (node) {
    case 'select':
      return ['option', 'option-group', 'divider'];

    case 'option-group':
      return ['option', 'divider'];

    case 'divider':
      return [undefined];

    case 'option':
    default:
      return [external];
  }
};

const validateNode = (
  nodes: TypeOrArray<Template>,
  ofType: InternalElementType,
  toBeOneOf: Array<InternalElementType | undefined>
): void => {
  toArray(nodes).forEach((node) => {
    const {
      props: { children } = {},
    } = node;

    if (!toBeOneOf.includes(element(node).type)) {
      const expectation = toBeOneOf.map((item) =>
        is.nullish(item) ? 'nothing or function' : parse.toCamelCase(item, false)
      );

      invariant(
        false,
        [
          `Invalid element found inside <${parse.toCamelCase(ofType, false)} />.`,
          `Expected to find "${expectation.join(', ')}" only.`,
        ].join('\n')
      );
    }

    if (!is.nullish(children)) {
      validateNode(
        shallowCopy(children as unknown as Template),
        element(node).type!,
        getCompositionWhitelist(element(node).type!)
      );
    }
  });
};

const validate: PipeMiddleware<TypeOrArray<Template>> = (templates, loading, disabled) => {
  const nodes = shallowCopy(toArray(templates));

  invariant(
    loading || disabled || nodes?.length,
    [
      '<Select /> cannot be empty, it should contain one or more <Option /> element.',
      '',
      'Example:',
      '<Select {...}>',
      ' <Option value="foo">Foo</Option>',
      '</Select>',
    ].join('\n')
  );

  // composition type validation
  validateNode(nodes, 'select', getCompositionWhitelist('select'));

  return nodes;
};

export default validate;

import { defineMessages } from 'react-intl';

export default {
  components: {
    toast: {
      generic: defineMessages({
        error: {
          id: 'components.toast.generic.error',
          defaultMessage: 'Something went wrong. Please try again.',
        },
      }),
    },
  },
};

const loyaltyLevels = [
  'Crush',
  'Lover',
  'Sweetheart',
  'Lancelot',
  'Romeo',
  'Casanova',
  'Signature',
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
  'Diamond',
  'Eros',
] as const;

const gifMasks = ['Eros'];

export { gifMasks, loyaltyLevels };

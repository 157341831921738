import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';

import Html from '../../../html';
import Option from '../option';
import element from '../../utils/element';
import type {
  InternalComponent,
  InternalComponentProps,
  InternalOptionGroupElement,
  InternalOptionElement,
} from '../../contracts';

import styles from './group.module.scss';

const Group: InternalComponent<HTMLDivElement, InternalOptionGroupElement> = (props) => {
  const { testId, title, selectedValue, className, style, children, onSelect, ...rest } = props;

  const isMobileDevice = React.useMemo(() => {
    return is.mobileDevice;
  }, []);

  const renderElementType = React.useCallback(
    (target: React.ReactElement) => {
      switch (element(target).type) {
        case 'option': {
          const el = target as React.ReactElement<InternalComponentProps<HTMLButtonElement, InternalOptionElement>>;

          return (
            <Option key={el.key} {...el.props} selected={el.props.value === selectedValue} onSelect={onSelect}>
              {el.props.children}
            </Option>
          );
        }

        default:
          return null;
      }
    },
    [selectedValue, onSelect]
  );

  if (isMobileDevice) {
    return (
      <Html.optgroup
        testId={testId && `${testId}-group`}
        label={title}
        className={className}
        style={style}
        arias={rest}
      >
        {React.Children.toArray(children).map((el) => renderElementType(el as React.ReactElement))}
      </Html.optgroup>
    );
  }

  return (
    <Html.div
      testId={testId && `${testId}-group`}
      className={[styles.group, ...toArray(className)]}
      style={style}
      arias={rest}
    >
      <Html.p className={styles.title}>{title}</Html.p>
      {React.Children.toArray(children).map((el) => renderElementType(el as React.ReactElement))}
    </Html.div>
  );
};

export default Group;

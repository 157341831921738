import React from 'react';
import toArray from 'utils/to-array';
import parse from 'utils/parse';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';
import type { PopoverElement } from '../popover';
import Popover from '../popover';

import styles from './tooltip.module.scss';

interface TooltipElement
  extends StyledElement<HTMLDivElement>,
    AriaAttributes,
    TestAutomation,
    Pick<PopoverElement, 'position' | 'boundary' | 'open'> {
  content: React.ReactNode | undefined;
  disabled?: boolean;
  children: React.ReactNode;
  isDefaultOpen?: boolean;
}

const Tooltip: React.FunctionComponent<TooltipElement> = (props) => {
  const { testId, className, style, children, content, position, boundary, open, disabled, isDefaultOpen, ...rest } =
    props;
  const trigger: PopoverElement['trigger'] = { onMouseEnter: true, onMouseLeave: false };

  const renderContent: PopoverElement['content'] = (direction): React.ReactNode => {
    if (!content) return;

    return (
      <Html.div className={styles.wrapper}>
        <Html.div
          testId={testId}
          className={[styles.content, styles[direction], ...toArray(className)]}
          style={style}
          arias={rest}
        >
          {content}
        </Html.div>
      </Html.div>
    );
  };

  return (
    <Popover
      testId={testId && parse.toTestId(testId, 'wrapper')}
      content={renderContent}
      position={position}
      boundary={boundary}
      trigger={trigger}
      open={(open || isDefaultOpen) && Boolean(content)}
      disabled={!content || disabled}
      className={styles.tooltip}
    >
      {children}
    </Popover>
  );
};

export type { TooltipElement };
export default React.memo(Tooltip);
